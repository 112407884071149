/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../landing/images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:

// eslint-disable-next-line
import "../landing/sass/style.scss"
import ahoy from "ahoy.js"
import * as Sentry from "@sentry/react";

const isProduction = process.env.RAILS_ENV === "production"
// eslint-disable-next-line no-undef
const sentryDsn = App.SENTRY_DSN || process.env.SENTRY_MARKETING_DSN
console.log('sentry', sentryDsn)
// eslint-disable-next-line no-undef
const sentryEnv = App.SENTRY_CURRENT_ENV || process.env.RAILS_ENV

//boot sentry error tracking as early as possibles
isProduction &&
sentryDsn &&
Sentry.init({ dsn: sentryDsn, environment: sentryEnv })

ahoy.trackView();
ahoy.trackClicks("a, button, input[type=submit]");
ahoy.trackSubmits("form");
ahoy.trackChanges("input, textarea, select");
